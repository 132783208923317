import React, { Component } from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { navigate, Link } from 'gatsby';
import Select, { components } from 'react-select';

import { toDanishCurrency } from '../utilities/helpers';
import { ThemeProvider, ThemeContext } from '../context/ThemeProvider';
import CheckoutForm from '../components/CheckoutForm';
import listingService from '../services/ListingService';
import blockonomicsService from '../services/BlockonomicsService';

import headerLogo from '../images/logo_footer.svg';
import iconAmex from '../images/ico-amex.svg';
import iconMaestro from '../images/ico-maestro.svg';
import iconMastercard from '../images/ico-mastercard.svg';
import iconVisa from '../images/ico-visa.svg';
import iconVisaElectron from '../images/ico-visa-electron.svg';
import loader from '../images/page-loader.svg';

import '../styles/checkout.scss';
import SEO from '../components/seo';
import Axios from 'axios';

import { countryCodeAndIso } from '../utilities/helpers';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const stripePromise = loadStripe('pk_live_51O9bMED9HaAN45om0SWqFuDQHlzHpVAY3EiaSe7mUDrx7a9wRkBqufcFSv2UL38ja0A4dCxj8mPwv3TdHkBn4sVc00rEONuXzB');

export class Checkout extends Component {
    pollInterval = null;

    constructor() {
        super();

        this.state = {
            data: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
                acceptPrivacy: false,
                subscribeNewsletter: false,
                nameOnCard: ''
            },
            stripe: false,
            loading: true,
            couponCode: '',
            showCouponField: false,
            isSubmitting: false,
            errorMessage: '',
            phoneCode: '',
            error: {},
            menuIsOpen: false,
            countryValue: '',
            isBitcoinEnabled: false,
            blockonomicsCustomSettings: {},
            hasActiveBitcoinPaymentSession: false 
        }
    }

    async componentDidMount() {
        if (typeof this.props.location.state === "undefined" || this.props.location.state === null) {
            navigate('/');
        }
        else {
            this.init();

            const sessionId = this.props.location.state.sessionId;
            // Call the function every 1 minute
            this.pollInterval = setInterval(() => listingService.pollSession({ id: sessionId }), 60000);

            var isEnabled = await listingService.isBitcoinPaymentEnabled();
            if (isEnabled) {
                this.setState({ isBitcoinEnabled: true }, () => {
                    blockonomicsService.getCustomSettings().then((response) => {
                        this.setState({ blockonomicsCustomSettings: response })
                    });
                });
                this.loadBlockonomicsWidget();
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.pollInterval);

        // Remove checkout page from history if user clicks the back button of the browser
        if (typeof window !== 'undefined') {
            window.history.pushState(null, null, window.location.pathname);
        }
    }

    init() {
        // stripePromise = loadStripe('pk_live_P0FSIEtbwU1GSvgvEM3DYuUZ');
        setTimeout(() => {
            this.setState({ stripe: true});
        }, 1000);
    }

    handleChange = (e) => {
        const { name, value, checked, type } = e.currentTarget;
        const { data } = this.state;
        
        if (e.currentTarget.name === 'phone') {
            data[name] = value.replace(/\D/g,'');
        }
        else if (type === 'checkbox') {
            data[name] = checked;
        }
        else {
            data[name] = value;
        }

        this.setState({ data });
    }

    toggleCoupon = () => {
        const { showCouponField } = this.state;
        this.setState({ showCouponField: !showCouponField });
    }
    
    onCouponChange = (e) => {
        const { value } = e.currentTarget;

        if (value.length >= 2) {
            this.setState({ couponCode: value });
            // window.clearTimeout(this.couponTimeout);

            // this.couponTimeout = setTimeout(async () => {
            //     // API all for coupon
            // }, 1500);
        }
        else {
            this.setState({ couponCode: value });
        }
    }

    handleChangeCountry = (e) => {
        const phoneValue = e.value.split('|')[1];
        this.setState({ phoneCode: phoneValue });
    }

    onPayment = async ({ token, reservationId, guestId }) => {
        source.cancel();
        source = CancelToken.source();

        const { listingInfo, listingId, cleaningFee } = this.props.location.state;
        let { data } = this.state;

        let payload = {
            listingId,
            reservationId,
            guestId,
            cardToken: token,
            fareAccomodation: listingInfo.totalPrice + cleaningFee,
            currency: 'DKK',
            message: data.message,
            firstName: data.firstName,
            lastName: data.lastName
        };

        let paymentResponse = await listingService.addPaymentToReservation(payload, source.token);

        if (paymentResponse.success) {
            let reservationPayload = {
                reservationId,
                subscribeNewsletter: data.subscribeNewsletter,
                hasAutoPayment: paymentResponse.data.hasAutoPayment
            };
            let reservation = await listingService.refreshReservationStatus(reservationPayload, source.token);
    
            if (reservation.success) {
                if (reservation.data.isConfirmed) {
                    return reservation.data;
                }
                else {
                    alert(reservation.errorMessage);
                }
            }
            else {
                alert(reservation.errorMessage);
            }
        }
        else {
            alert(paymentResponse.errorMessage);
        }

        this.setState({ isSubmitting: false }, () => {
            document.body.style.overflowY = '';
        });
    }

    logError = (name, errorDetail) => {
        const { listingInfo, listingId } = this.props.location.state;
        let message = {
            errorDetails: errorDetail,
            listingDetails: {
                listingId,
                listingInfo
            }
        }

        listingService.logError({ name, message : JSON.stringify(message) }, source.token);
    }

    onPaymentSuccess = () => {
        const { data } = this.state;
        const { checkin, checkout, guests, address, sessionId } = this.props.location.state;
        
        listingService.deleteBookingSession({ id: sessionId });

        this.setState({ isSubmitting: false }, () => {
            document.body.style.overflowY = '';

            navigate('/checkout/success', 
                {
                    replace: true,
                    state: {
                        guestName: `${data.firstName} ${data.lastName}`,
                        propertyAddress: address.fullAddress,
                        guestCount: guests,
                        checkinDate: checkin,
                        checkoutDate: checkout
                    }
                }
            );
        });
    }

    verifyReservation = () => {
        let { data, errorMessage, error } = this.state;
        let hasError = false;
        let isAcceptedPrivacy = true;
        error = {};
        
        if (!data.firstName) {
            hasError = true;
            error.firstName = "Please fill in all required fields.";
        }
        if (!data.lastName) {
            hasError = true;
            error.lastName = "Please fill in all required fields.";
        }
        if (!data.email) {
            hasError = true;
            error.email = "Please fill in all required fields.";
        }
        if (!data.phone) {
            hasError = true;
            error.phone = "Please fill in all required fields.";
        }
        if (!data.acceptPrivacy) {
            isAcceptedPrivacy = false;
        }

        if (hasError && !isAcceptedPrivacy) {
            errorMessage = "Please fill in all required fields and make sure that you have agreed to the Privacy Policy.";            
            this.setState({ errorMessage, error });
            return false;
        }
        else if (hasError) {
            errorMessage = "Please fill in all required fields.";            
            this.setState({ errorMessage, error });
            return false;
        }
        else if (!isAcceptedPrivacy) {
            errorMessage = "Please read and accept the Privacy Policy.";            
            this.setState({ errorMessage, error });
            return false;
        }
        else {
            this.setState({ error : {}, errorMessage: '' });
            return true;
        }
    }

    onReservation = async (cardToken) => {
        // API Call
        const { checkin, checkout, guests, listingInfo, listingId, cleaningFee } = this.props.location.state;
        let { data, phoneCode } = this.state;

        let param = {
            guestyReservation: {
                listingId: listingId,
                money: {
                    fareAccomodation: listingInfo.totalPrice + cleaningFee,
                    currency: 'DKK'
                },
                guest: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: '+' + phoneCode + data.phone
                },
                guestsCount: guests
            },
            checkInDate: checkin,
            checkOutDate: checkout,
            token:cardToken,
            message: data.message,
            // subscribeNewsletter: data.subscribeNewsletter
        };

        this.setState({ isSubmitting: true }, () => {
            document.body.style.overflowY = 'hidden';
        });

        source.cancel();
        source = CancelToken.source();

        let reservationResponse = await listingService.createReservation(param, source.token);

        if (reservationResponse.success) {
            return {
                // customerId: reservationResponse.Data.customerId,
                // reservationId: reservationResponse.Data.reservationId,
                // guestId: reservationResponse.Data.guestId,
                success: reservationResponse.success,
                listingId
            }
        }
        else {
            alert(reservationResponse.errorMessage);
            this.setState({ isSubmitting: false }, () => {
                document.body.style.overflowY = '';
            });

            return null;
        }
    }

    loadBlockonomicsWidget = () => {
        if (document.querySelector("#script-blockonomics"))
            return;

        const script = document.createElement("script");
        script.id = "script-blockonomics";
        script.src = "https://blockonomics.co/js/pay_widget.js";
        document.body.appendChild(script);
        
        const { checkin, checkout, guests, listingInfo, listingId, address, bpid, cleaningFee } = this.props.location.state;
        let { data, phoneCode, blockonomicsCustomSettings } = this.state;
        let that = this;

        const { price: totalAmount, cleaningFee: newCleaningFee } = this.recomputeWithBitcoinDiscount(listingInfo.totalPrice, cleaningFee);

        window.blockonomicsPaymentCallback = async function (payment) {
            let param = {
                guestyReservation: {
                    listingId: listingId,
                    money: {
                        fareAccomodation: totalAmount,
                        currency: 'DKK'
                    },
                    guest: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phone: '+' + phoneCode + data.phone
                    },
                    guestsCount: guests
                },
                checkInDate: checkin,
                checkOutDate: checkout,
                token: payment.txid,
                productId: bpid,
                cleaningFee: newCleaningFee,
                amountInBtc: payment.value,
                paymentStatus: payment.status,
                paymentAddress: payment.addr || payment.txid
            };

            that.setState({ isSubmitting: true }, () => {
                document.body.style.overflowY = 'hidden';
            });

            source.cancel();
            source = CancelToken.source();

            let reservationResponse = await listingService.createReservationOpenApi(param, source.token);

            if (reservationResponse.success) {
                navigate('/checkout/success', 
                    {
                        replace: true,
                        state: {
                            guestName: `${data.firstName} ${data.lastName}`,
                            propertyAddress: address.fullAddress,
                            guestCount: guests,
                            checkinDate: checkin,
                            checkoutDate: checkout,
                            isBtcPayment: true,
                            transactionId: payment.txid
                        }
                    }
                );
            }
            else {
                alert(reservationResponse.errorMessage);
                that.setState({ isSubmitting: false }, () => {
                    document.body.style.overflowY = '';
                });
    
                return null;
            }
        }
    }

    recomputeWithBitcoinDiscount = (totalPrice, cleaningFee, isCoverPlatformFee = true) => {
        const { blockonomicsCustomSettings } = this.state;

        let newPrice = totalPrice;
        let newCleaningFee = cleaningFee;
        let discount = isCoverPlatformFee ? 1 : 0; // initial discount for bitcoin payment

        if (blockonomicsCustomSettings.enabled && blockonomicsCustomSettings.discount > 0) {
            // Ensuring that 50% is the maximum discount
            discount += blockonomicsCustomSettings.discount > 50 ? 50 : blockonomicsCustomSettings.discount;
            newPrice = newPrice - (newPrice * discount  / 100);
            if (cleaningFee !== null && cleaningFee > 0) {
                newCleaningFee = newCleaningFee - (newCleaningFee * discount / 100);
            }
        }

        return {
            price: newPrice,
            cleaningFee: newCleaningFee
        }
    }

    handleBitcoinPayment = async (e) => {
        if (this.state.hasActiveBitcoinPaymentSession) {
            e.preventDefault();
            return;            
        }

        const isValid = this.verifyReservation();
        if (!isValid) {
            return;
        }

        // eslint-disable-next-line no-undef
        if (Blockonomics !== null && Blockonomics !== undefined && Blockonomics.widget !== null && Blockonomics.widget !== undefined) {
            let totalAmount = this.props.location.state.listingInfo.totalPrice + this.props.location.state.cleaningFee;

            const { price: newtotalAmount, cleaningFee } = this.recomputeWithBitcoinDiscount(totalAmount, 0);

            // eslint-disable-next-line no-undef
            Blockonomics.widget({
                msg_area: 'blockonomics-payment-area',
                uid: this.props.location.state.bpid,
                amount: newtotalAmount + cleaningFee,
                email: this.state.data.email
            });

            this.setState({ hasActiveBitcoinPaymentSession: true }, () => {
                setTimeout(() => {
                    this.setState({ hasActiveBitcoinPaymentSession: false });
                }, 5000);
            });
        }
        else {
            alert("Pay with bitcoin script failed to load. Please refresh the page and try again.");
            return;
        }
    }
    
    render() {
        const { stripe, data, showCouponField, couponCode, isSubmitting, errorMessage, error, menuIsOpen, isBitcoinEnabled, blockonomicsCustomSettings } = this.state;
        let { theme, seo } = this.props.pageContext;
        const { location } = this.props;
        let listingId;

        if (location && location.state) {
            listingId = location.state.listingId;
        }

        //const { listingId } = this.props.location.state;
        if (!stripe) {
            return (
                <div className="listings-loader"><img src={loader} alt="Loading..." /></div>
            );
        }

        if (!seo) {
            seo = {};
            seo.Page = {
                Title: "Checkout"
            }
        }
        
        let checkin, checkout, guests, listingInfo, title, propertyType, cleaningFee, address, bpid;

        if (location && location.state) {
            checkin = location.state.checkin;
            checkout = location.state.checkout;
            guests = location.state.guests;
            listingInfo = location.state.listingInfo;
            title = location.state.title;
            propertyType = location.state.propertyType;
            cleaningFee = location.state.cleaningFee
            address = location.state.address;
            bpid = location.state.bpid;
        }

        let countryOptions = [];
        let countryIsoCodes = countryCodeAndIso();

        for (let index = 0; index < countryIsoCodes.length; index++) {
            countryOptions.push({
                value: index + "|" + countryIsoCodes[index].countrycode,
                label: countryIsoCodes[index].countrycode,
                flagIcon: countryIsoCodes[index].isocode.toLowerCase(),
            });
        }

        const { price: discountedPrice, cleaningFee: discountedCleaningFee } = this.recomputeWithBitcoinDiscount(listingInfo.totalPrice, cleaningFee, false);

        let links = [];

        links.push(<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/css/flag-icon.min.css" />);

        return (
            <React.Fragment>
                <SEO {...seo} links={links} />
                <ThemeProvider theme={theme}>
                    <div className="checkout">
                        {
                            isSubmitting && 
                            <div className="loader">
                                <img src={loader} alt="Loading..." />
                                <div>Processing...</div>
                            </div>
                        }
                        <div className="checkout__header-logo">
                            <Link to="/">
                                <img src={headerLogo} alt="logo" />
                            </Link>
                        </div>
                        <div className="checkout__booking-info">
                            <div className="checkout__booking-info__price-summary">
                                <div className="checkout__booking-info__title">{title}</div>
                                <ul>
                                    <li>
                                        <div>Location:</div>
                                        { address && <span>{address.city}, {address.country}</span>}
                                    </li>
                                    <li>
                                        <div>Guest:</div>
                                        <span>{guests}</span>
                                    </li>
                                    <li>
                                        <div>Type:</div>
                                        <span>{propertyType}</span>
                                    </li>
                                    <li>
                                        <div>Avg. per night:</div>
                                        { listingInfo && <span>{toDanishCurrency(listingInfo.avgPerNight)}</span>}
                                    </li>
                                    <li>
                                        <div>Check in:</div>
                                        <span>{checkin}</span>
                                    </li>
                                    <li>
                                        <div>{`${listingInfo.totalNights} night${listingInfo.totalNights > 1 ? 's': ''}:`} </div>
                                        { listingInfo && <span>{toDanishCurrency(listingInfo.totalPrice)}</span>}
                                    </li>
                                    <li>
                                        <div>Check out:</div>
                                        <span>{checkout}</span>
                                    </li>
                                    <li>
                                        <div>Cleaning fee:</div>
                                        <span>{toDanishCurrency(cleaningFee)}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="checkout__booking-info__price-total">
                                <div className="price-total-label">Total:</div>
                                { listingInfo && <div className="price-total">{toDanishCurrency(listingInfo.totalPrice + cleaningFee)}</div>}
                                { 
                                    listingInfo && blockonomicsCustomSettings.enabled && blockonomicsCustomSettings.discount > 0 && bpid !== "" &&
                                    <>
                                        <div className="discounted-price-label">
                                            Discount when paying bitcoin:
                                        </div>
                                        <div className="discounted-price">
                                            {toDanishCurrency((listingInfo.totalPrice + cleaningFee) - (discountedPrice + discountedCleaningFee))}
                                        </div>
                                    </>
                                }
                                <div className="cancellation-policy"><a href="/cancellation-policy" target="_blank">Cancellation Policy</a></div>
                            </div>
                        </div>
                        <div className="layout-row checkout__main-info">
                            <div className="checkout__main-info__personal-info">
                                <div className="personal-info-title">
                                    Personal Info
                                </div>
                                <div className="form">
                                    {errorMessage && <div className="notification notification--error">{errorMessage}</div>}
                                    <div className="form-inline">
                                        <div className="checkout-field">
                                            <span className="content-name">First Name*</span>
                                            <input type="text" name="firstName" className={`${error["firstName"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.firstName} />
                                        </div>
                                        <div className="checkout-field">
                                            <span className="content-name">Last Name*</span>
                                            <input type="text" name="lastName" className={`${error["lastName"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.lastName} />
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="checkout-field">
                                            <span className="content-name">Email*</span>
                                            <input type="email" name="email" className={`${error["email"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.email} />
                                        </div>
                                        <div className="checkout-field">
                                            <span className="content-name">Phone*</span>
                                            <div className="phone-field" style={{ position: 'relative'}}>
                                                <Select options={countryOptions} isSearchable={true} onChange={this.handleChangeCountry} className="country-select" classNamePrefix="country-select"
                                                    menuIsOpen={menuIsOpen}
                                                    onMenuOpen={() => {
                                                        let flag = document.getElementsByClassName('country-select__single-value')[0];
                                                        if (flag) {
                                                            flag.style.visibility = 'hidden';
                                                        }
                                                        
                                                        this.setState({ menuIsOpen: true })
                                                    }}
                                                    onMenuClose={() => {
                                                        let flag = document.getElementsByClassName('country-select__single-value')[0];
                                                        if (flag) {
                                                            flag.style.visibility = 'visible';
                                                        }

                                                        setTimeout(() => {
                                                            let valueContainer = document.getElementsByClassName('country-select__value-container')[0];
                                                            if (valueContainer) {
                                                                valueContainer.style.height = 'unset';
                                                            }
                                                        }, 100);
                                                
                                                        setTimeout(() => {
                                                            let valueContainer = document.getElementsByClassName('country-select__value-container')[0];
                                                            if (valueContainer) {
                                                                valueContainer.style.height = '100%';
                                                            }
                                                        }, 150);

                                                        this.setState({ menuIsOpen: false })
                                                    }}
                                                    defaultValue={countryOptions[0]}
                                                    components={{
                                                        Option: Option,
                                                        SingleValue: SingleValue
                                                    }}
                                                />
                                                <input type="number" name="phone" className={`${error["phone"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.phone} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout-field" style={{ marginBottom: 20 }}>
                                        <span className="content-name">Message</span>
                                        <textarea className="checkout-field--message" type="text" name="message" rows={5} onChange={this.handleChange} value={data.message} />
                                    </div>
                                    <div style={{marginLeft: 10}}>
                                        <input type="checkbox" id="acceptPrivacy" name="acceptPrivacy" onChange={this.handleChange} value={data.acceptPrivacy} />
                                        <label htmlFor="acceptPrivacy" className="checkbox-label privacy-policy"><span>I have read and accept the <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</span></label>
                                    </div>
                                    <div style={{marginLeft: 10}}>
                                        <input type="checkbox" id="subscribeNewsletter" name="subscribeNewsletter" onChange={this.handleChange} value={data.subscribeNewsletter} />
                                        <label htmlFor="subscribeNewsletter" className="checkbox-label"><span>I am interested in receiving discounts, promotions and news about Dinesen Collection.</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout__main-info__billing-info">
                                <div className="billing-details-title">
                                    <div>Billing details</div>
                                    <div className="billing-info__supported-card-type">
                                        <ul>
                                            <li><img src={iconVisa} alt="Visa" /></li>
                                            <li><img src={iconMastercard} alt="Mastercard" /></li>
                                            <li><img src={iconMaestro} alt="Maestro" /></li>
                                            <li><img src={iconVisaElectron} alt="Visa Electron" /></li>
                                            <li><img src={iconAmex} alt="Amex" /></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="form">
                                    <Elements stripe={stripePromise}>
                                        <InjectedCheckoutForm data={data} onNameOnCardChange={this.handleChange}
                                            toggleCoupon={this.toggleCoupon} onCouponChange={this.onCouponChange} 
                                            showCouponField={showCouponField} couponCode={couponCode}
                                            onReservation={this.onReservation} onPayment={this.onPayment} verifyReservation={this.verifyReservation}
                                            onPaymentSuccess={this.onPaymentSuccess} logError={this.logError} 
                                            isBitcoinEnabled={isBitcoinEnabled && bpid !== ""}
                                            handleBitcoinPayment={this.handleBitcoinPayment}
                                            blockonomicsCustomSettings={blockonomicsCustomSettings}
                                            bookingInfo={{
                                                checkInDate : checkin,
                                                checkOutDate : checkout,
                                                guestyReservation : {
                                                    listingId : listingId,
                                                    guestsCount : guests,
                                                }
                                            }}/>
                                    </Elements>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

const InjectedCheckoutForm = ({ data, onNameOnCardChange, toggleCoupon, onCouponChange, showCouponField, couponCode, onReservation, onPayment, verifyReservation, onPaymentSuccess, logError, isBitcoinEnabled, blockonomicsCustomSettings, handleBitcoinPayment,bookingInfo }) => (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} elements={elements} data={data} onNameOnCardChange={onNameOnCardChange}
            toggleCoupon={toggleCoupon}
            onCouponChange={onCouponChange}
            showCouponField={showCouponField}
            couponCode={couponCode}
            onReservation={onReservation}
            onPayment={onPayment}
            verifyReservation={verifyReservation}
            onPaymentSuccess={onPaymentSuccess}
            logError={logError}
            isBitcoinEnabled={isBitcoinEnabled} 
            blockonomicsCustomSettings={blockonomicsCustomSettings}
            handleBitcoinPayment={handleBitcoinPayment}
            bookingInfo={bookingInfo}
        />
      )}
    </ElementsConsumer>
);

Checkout.contextType = ThemeContext;

const Option = props => (
    <components.Option {...props}>        
        <span className={`flag-icon flag-icon-${props.data.flagIcon}`} style={{display: 'inline-block', marginRight: '5px'}}></span>
        <span style={{display: 'inline-block'}}>{props.data.label}</span>
    </components.Option>
)

const SingleValue = props => (
    <components.SingleValue {...props}>        
        <span className={`flag-icon flag-icon-${props.data.flagIcon}`}></span>
    </components.SingleValue>
)

export default Checkout;
