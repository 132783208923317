import React, { useContext, useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { ThemeContext } from '../context/ThemeProvider';
import Axios from 'axios';
import { stripeErrorCodes } from '../utilities/StripeErrorCodes';

import iconCcv from '../images/ico-ccv.svg';
import iconAmex from '../images/ico-amex.svg';
// import iconMaestro from '../images/ico-maestro.svg';
import iconMastercard from '../images/ico-mastercard.svg';
import iconVisa from '../images/ico-visa.svg';
// import iconVisaElectron from '../images/ico-visa-electron.svg';
import PaymentService from '../services/PaymentService';
import listingService from '../services/ListingService';

const CheckoutForm = ({ stripe, elements, data, onNameOnCardChange, toggleCoupon, onCouponChange, showCouponField, couponCode, onReservation, 
    onPayment, verifyReservation, onPaymentSuccess, logError, isBitcoinEnabled, blockonomicsCustomSettings, handleBitcoinPayment, bookingInfo }) => {
    // const stripe = useStripe();
    // const elements = useElements();
    const { buttonPrimary } = useContext(ThemeContext);
    const [cardType, setCardType] = useState(null);
    const [error, setError] = useState(null);

    const CancelToken = Axios.CancelToken;
    let source = CancelToken.source();

    const handleSubmit = async (event) => {
        event.preventDefault();
        source.cancel();
        source = CancelToken.source();

        if (!stripe || !elements) {
            return;
        }
        const cardError = {};
        const isValid = verifyReservation();
        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);
        const hasNameValue = !!data.nameOnCard;

        if(!isValid) {
            return;
        }

        var availability = await listingService
            .getListingAvailabilityByDate(bookingInfo.guestyReservation.listingId, bookingInfo.checkInDate, bookingInfo.checkOutDate, source.token);
            
        if (!availability.success) {
            cardError.errorMessage = "Unable to process payment. Please contact the site administrator.";
            setError(cardError.errorMessage);
            return;
        }
        else {
            //check if availability.Data contains an object with isValid = false
            var isAvailable = availability.data.find(x => x.isValid === false);
            if (isAvailable) {
                cardError.errorMessage = "The dates you have selected are no longer available. Please select another date.";
                setError(cardError.errorMessage);
                return;
            }
        }

        const { success,  data : clientSecret } = await PaymentService.CreatePaymentIntent(bookingInfo,source.token);

        if (!success) {
            cardError.errorMessage = "Unable to process payment. Please contact the site administrator.";
            setError(cardError.errorMessage);
            return;
        }
        
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardNumberElement,
                billing_details: {
                    name: data.nameOnCard,
                },
            },
        });
        
        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: cardNumberElement
        // });

        let errorCode = '';
        if (error) {
            if (error.code) {
                errorCode = error.code;
            }

            let customErrorMessage = stripeErrorCodes.find(x => x.code === error.code);

            if (errorCode === 'card_declined') {
                cardError.cardDeclinedMessage = error.message;
            }
            else if (customErrorMessage) {
                cardError.errorMessage = customErrorMessage.message;
            }
            else if (error.message){
                cardError.errorMessage = error.message;
            }
            else {
                cardError.errorMessage = "An error has occurred during the process of payment. Please contact the site administrator.";
            }
            
            try {
                logError("GuestyBookingWorkflow", error);
            }
            catch { }
        }

        if (cardNumberElement._empty || cardNumberElement._invalid || errorCode === 'incorrect_number') {
            if (cardNumberElement._invalid || errorCode === 'incorrect_number') {
                cardError.cardNumberErrorMessage = "Your card number is invalid. ";
                cardError.cardNumberInvalid = true;
            }
            else {
                cardError.cardNumber = true;
            }
        }
        if (cardExpiryElement._empty || cardExpiryElement._invalid) {
            if (cardExpiryElement._invalid) {
                cardError.cardExpiryErrorMessage = "Your expiration date is invalid. ";
                cardError.cardExpiryInvalid = true;
            }
            else {
                cardError.cardExpiry = true;
            }
        }
        if (cardCvcElement._empty) {
            cardError.cardCvc = true;
        }
        if (!data.nameOnCard) {
            cardError.cardName = true;
        }

        if (isValid && Object.keys(cardError).length <= 0 && hasNameValue) {

            var paymentMethodToken = paymentIntent.payment_method;
            const reservationDetails = await onReservation(paymentMethodToken);
            // debugger;
            // var reservationDetails = {
            //     success: true,
            // }
            
            if (reservationDetails === null) {
                return;
            }
            else {
                if (reservationDetails.success) {
                    onPaymentSuccess();
                    // onPayment({ token: paymentMethod, reservationId: reservationDetails.reservationId, guestId: reservationDetails.guestId })
                    // .then(data => {
                    //     // Update stripe customer description
                    //     if (data) {
                    //         onPaymentSuccess();
                    //         // stripe.customers.update(customerId, 
                    //         // { 
                    //         //     description: data.confirmationCode,
                    //         //     metadata: {
                    //         //         listingId: reservationDetails.listingId,
                    //         //         confirmationCode: data.confirmationCode
                    //         //     }
                    //         // }, 
                    //         // (err, customer) => {
                    //         //     if (!err) {
                    //         //     }
                    //         // });
                    //     }
                    // });
                }
            }
        }
        else {
            if (Object.keys(cardError).length > 0) {
                cardError.message = [];
                if (cardError.cardNumber || cardError.cardExpiry || cardError.cardCvc || cardError.cardName) {
                    cardError.message.push("Please fill in the credit card details.");
                }
                
                if (cardError.cardNumberErrorMessage) {
                    cardError.message.push(cardError.cardNumberErrorMessage);
                }
                if (cardError.cardExpiryErrorMessage) {
                    cardError.message.push(cardError.cardExpiryErrorMessage);
                }
                if (cardError.cardDeclinedMessage) {
                    cardError.message.push(cardError.cardDeclinedMessage);
                }
                if (cardError.errorMessage) {
                    cardError.message.push(cardError.errorMessage);
                }
                setError(cardError.message.join("<br/>"));
            }
            else {
                setError(null);
            }

            return;
        }
    };

    const handleOnChange = (e) => {
        if (e.complete) {
            switch(e.brand) {
                case 'visa':
                    setCardType(iconVisa);
                    break;
                case 'mastercard':
                    setCardType(iconMastercard);
                    break;
                case 'amex':
                    setCardType(iconAmex);
                    break;
                default:
                    setCardType(null);
                    break;
            }
        }
    }

    return (
        <React.Fragment>
            <div className={`notification ${error ? 'notification--error' : ''}`} dangerouslySetInnerHTML={{ __html: error }}>
            </div>
            <div className="checkout-field">
                <span>Card number*</span>
                <div className={`billing-info__card-container ${error && (error.cardNumber || error.cardNumberInvalid) ? 'has-error' : ''}`}>
                    <CardNumberElement options={{
                        style: baseStripeStyle
                    }} onChange={handleOnChange}/>
                    { cardType && <img src={cardType} alt={cardType} /> }
                </div>
            </div>
            <div className="checkout-field">
                <span className="content-name">Name on card* (exactly as shown on card)</span>
                <input type="text" name="nameOnCard" autoComplete="off" className={`${error && error.cardName ? 'has-error' : ''}`} onChange={(e) => onNameOnCardChange(e)} value={data.nameOnCard} />
            </div>
            <div className={`billing-info__exp_cv`}>
                <div>
                    <span>Expiration Date*</span>
                    <div className={`billing-info__exp-container ${error && (error.cardExpiry || error.cardExpiryInvalid) ? 'has-error' : ''}`}>
                        <CardExpiryElement options={{
                            style: baseStripeStyle
                        }} />
                    </div>
                </div>
                <div>
                    <span>Security Code*</span>
                    <div className={`billing-info__cv-container ${error && error.cardCvc ? 'has-error' : ''}`}>
                        <CardCvcElement options={{
                            style: baseStripeStyle
                        }} />
                    </div>
                    <span className="ic-ccv" style={{ backgroundImage: `url(${iconCcv})` }}></span>
                </div>
            </div>
            <div className="billing-info__actions">
                {/* <div style={{
                    verticalAlign: showCouponField ? 'top' : '',
                    paddingTop: showCouponField ? 'unset' : '10px'
                }}>
                    { !showCouponField && <span className="billing-info__coupon" onClick={(e) => toggleCoupon()}>Got a coupon?</span> }
                    { showCouponField && 
                        <div className="checkout-field">                            
                            <span className="content-name">Got a coupon?</span>
                            <input type="text" name="couponCode" autoFocus={showCouponField} value={couponCode} onChange={(e) => onCouponChange(e)} onBlur={e => {
                                const { value } = e.currentTarget;
                                if (value) {
                                    e.currentTarget.classList.add('has-value');
                                }
                                else {
                                    e.currentTarget.classList.remove('has-value');
                                    toggleCoupon();
                                }
                            }} />
                        </div>
                    }
                </div> */}
                <div>
                    {/* Book Now button */}
                    <button className={`btn ${buttonPrimary}`} type="button" onClick={(e) => handleSubmit(e)}>Book Now</button>
                </div>
            </div>
            <div className="billing-info__bitcoinpayment">
                {/* Payment stickers */}
                {
                    isBitcoinEnabled && 
                    <React.Fragment>
                        <div className="bitcoinpayment-container">
                            {
                                (blockonomicsCustomSettings.enabled && blockonomicsCustomSettings.discount > 0) &&
                                <div className="bitcoin-discount-message">{blockonomicsCustomSettings.message}</div>
                            }
                            <a className="blockoPayBtn" onClick={(e) => handleBitcoinPayment(e)}>
                                <img width="160" src="https://www.blockonomics.co/img/pay_with_bitcoin_medium.png" />
                            </a>
                        </div>
                        <div id="blockonomics-payment-area"></div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

const baseStripeStyle = {
    base: {
        fontSize: '16px'
    }
}

export default CheckoutForm;