import httpClient from "../utilities/HttpClient"

var API = {
  getBlockonomicsCustomSettings: "/api/blockonomics/getsettings",
}

class BlockonomicsService {
    async getCustomSettings(token) {
        return httpClient.Get(API.getBlockonomicsCustomSettings, null, token);
    }
}

const blockonomicsService = new BlockonomicsService();
export default blockonomicsService;