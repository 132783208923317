export const stripeErrorCodes = [
    { 
        code: "account_country_invalid_address", 
        message: "The country of the business address provided does not match the country of the account. Businesses must be located in the same country as the account." 
    },
    { 
        code: "account_invalid", 
        message: "The account ID provided as a value for the Stripe-Account header is invalid. Check that your requests are specifying a valid account ID."
    },
    { 
        code: "account_number_invalid", 
        message: "The bank account number provided is invalid (e.g., missing digits). Bank account information varies from country to country."
    },
    { 
        code: "acss_debit_session_incomplete", 
        message: "The ACSS debit session is not ready to transition to complete status yet. Please try again the request later." 
    },
    { 
        code: "amount_too_large", 
        message: "The specified amount is greater than the maximum amount allowed." 
    },
    { 
        code: "amount_too_small", 
        message: "The specified amount is less than the minimum amount allowed." 
    },
    { 
        code: "api_key_expired", 
        message: "The API key provided has expired." 
    },
    { 
        code: "authentication_required", 
        message: "The payment requires authentication to proceed." 
    },
    { 
        code: "balance_insufficient", 
        message: "The transfer or payout could not be completed because the associated account does not have a sufficient balance available." 
    },
    { 
        code: "bank_account_declined", 
        message: "The bank account provided can not be used to charge, either because it is not verified yet or it is not supported." 
    },
    { 
        code: "bank_account_exists", 
        message: "The bank account provided already exists on the specified Customer object." 
    },
    { 
        code: "bank_account_unusable", 
        message: "The bank account provided cannot be used for payouts. A different bank account must be used." 
    },
    { 
        code: "bank_account_unverified", 
        message: "Your Connect platform is attempting to share an unverified bank account with a connected account." 
    },
    { 
        code: "bank_account_verification_failed", 
        message: "The bank account cannot be verified, either because the microdeposit amounts provided do not match the actual amounts, or because verification has failed too many times." 
    },
    { 
        code: "card_decline_rate_limit_exceeded", 
        message: "This card has been declined too many times." 
    },
    { 
        code: "card_declined", 
        message: "The card has been declined." 
    },
    // {  code: "cardholder_phone_number_required", message: "" },
    // {  code: "charge_already_captured", message: "" },
    // {  code: "charge_already_refunded", message: "" },
    // {  code: "charge_disputed", message: "" },
    // {  code: "charge_exceeds_source_limit", message: "" },
    // {  code: "charge_expired_for_capture", message: "" },
    // {  code: "charge_invalid_parameter", message: "" },
    // { code: "clearing_code_unsupported", message: "" },
    { 
        code: "country_code_invalid", 
        message: "The country code provided was invalid." 
    },
    { 
        code: "country_unsupported", 
        message: "Your platform attempted to create a custom account in a country that is not yet supported." 
    },
    // { code: "coupon_expired", message: "" },
    { 
        code: "customer_max_payment_methods", 
        message: "The maximum number of PaymentMethods for this Customer has been reached. Either detach some PaymentMethods from this Customer or proceed with a different Customer." 
    },
    // { code: "customer_max_subscriptions", message: "" },
    { 
        code: "email_invalid", 
        message: "The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted and only includes allowed characters." 
    },
    { 
        code: "expired_card", 
        message: "The card has expired. Check the expiration date or use a different card." 
    },
    // { code: "idempotency_key_in_use", message: "" },
    // { code: "incorrect_address", message: "" },
    { 
        code: "incorrect_cvc", 
        message: "The card’s security code is incorrect. Check the card’s security code or use a different card." 
    },
    { 
        code: "incorrect_number", 
        message: "The card number is incorrect. Check the card’s number or use a different card." 
    },
    { 
        code: "incorrect_zip", 
        message: "The card’s postal code is incorrect. Check the card’s postal code or use a different card." 
    },
    // { code: "instant_payouts_unsupported", message: "" },
    // { code: "intent_invalid_state", message: "" },
    // { code: "intent_verification_method_missing", message: "" },
    { 
        code: "invalid_card_type", 
        message: "The card provided as an external account is not supported for payouts. Provide a non-prepaid debit card instead." 
    },
    { 
        code: "invalid_characters", 
        message: "This value provided to the field contains characters that are unsupported by the field." 
    },
    { 
        code: "invalid_charge_amount", 
        message: "The specified amount is invalid. The charge amount must be a positive integer in the smallest currency unit, and not exceed the minimum or maximum amount." 
    },
    { 
        code: "invalid_cvc", 
        message: "The card’s security code is invalid. Check the card’s security code or use a different card." 
    },
    { 
        code: "invalid_expiry_month", 
        message: "The card’s expiration month is incorrect. Check the expiration date or use a different card." 
    },
    { 
        code: "invalid_expiry_year", 
        message: "The card’s expiration year is incorrect. Check the expiration date or use a different card." 
    },
    { 
        code: "invalid_number", 
        message: "The card number is invalid. Check the card details or use a different card." 
    },
    // { code: "invalid_source_usage", message: "" },
    // { code: "invoice_no_customer_line_items", message: "" },
    // { code: "invoice_no_payment_method_types", message: "" },
    // { code: "invoice_no_subscription_line_items", message: "" },
    // { code: "invoice_not_editable", message: "" },
    // { code: "invoice_payment_intent_requires_action", message: "" },
    // { code: "invoice_upcoming_none", message: "" },
    { 
        code: "livemode_mismatch", 
        message: "Test and live mode API keys, requests, and objects are only available within the mode they are in." 
    },
    { 
        code: "lock_timeout", 
        message: "This object cannot be accessed right now because another API request or Stripe process is currently accessing it. If you see this error intermittently, retry the request. If you see this error frequently and are making multiple concurrent requests to a single object, make your requests serially or at a lower rate."
    },
    { 
        code: "missing", 
        message: "Both a customer and source ID have been provided, but the source has not been saved to the customer." 
    },
    // { code: "not_allowed_on_standard_account", message: "" },
    // { code: "order_creation_failed", message: "" },
    // { code: "order_required_settings", message: "" },
    // { code: "order_status_invalid", message: "" },
    // { code: "order_upstream_timeout", message: "" },
    // { code: "out_of_inventory", message: "" },
    { 
        code: "parameter_invalid_empty", 
        message: "One or more required values were not provided. Make sure requests include all required parameters."
    },
    { 
        code: "parameter_invalid_integer", 
        message: "One or more of the parameters requires an integer, but the values provided were a different type. Make sure that only supported values are provided for each attribute."
    },
    { 
        code: "parameter_invalid_string_blank", 
        message: "One or more values provided only included whitespace. Check the values in your request and update any that contain only whitespace." 
    },
    { 
        code: "parameter_invalid_string_empty", 
        message: "One or more required string values is empty. Make sure that values contain at least one character." 
    },
    { 
        code: "parameter_missing", 
        message: "One or more required values are missing." 
    },
    { 
        code: "parameter_unknown", 
        message: "The request contains one or more unexpected parameters." 
    },
    // { code: "parameters_exclusive", message: "" },
    // { 
    //     code: "payment_intent_action_required", 
    //     message: "The provided payment method requires customer actions to complete, but error_on_requires_action was set." 
    // },
    // { 
    //     code: "payment_intent_authentication_failure", 
    //     message: "The provided payment method has failed authentication. Provide a new payment method to attempt to fulfill this PaymentIntent again." 
    // },
    // { 
    //     code: "payment_intent_incompatible_payment_method", 
    //     message: "The PaymentIntent expected a payment method with different properties than what was provided." 
    // },
    // { 
    //     code: "payment_intent_invalid_parameter", 
    //     message: "One or more provided parameters was not allowed for the given operation on the PaymentIntent." 
    // },
    // { 
    //     code: "payment_intent_mandate_invalid", 
    //     message: "The provided mandate is invalid and can not be used for the payment intent." 
    // },
    // { 
    //     code: "payment_intent_payment_attempt_expired", 
    //     message: "The latest payment attempt for the PaymentIntent has expired." 
    // },
    // { 
    //     code: "payment_intent_payment_attempt_failed", 
    //     message: "The latest payment attempt for the PaymentIntent has failed." 
    // },
    // { 
    //     code: "payment_intent_unexpected_state", 
    //     message: "The PaymentIntent’s state was incompatible with the operation you were trying to perform." 
    // },
    { 
        code: "payment_method_bank_account_already_verified", 
        message: "This bank account has already been verified." 
    },
    { 
        code: "payment_method_bank_account_blocked", 
        message: "This bank account has failed verification in the past and can not be used. " 
    },
    { 
        code: "payment_method_currency_mismatch", 
        message: "The currency specified does not match the currency for the attached payment method." 
    },
    // { 
    //     code: "payment_method_invalid_parameter", 
    //     message: "Invalid parameter was provided in the payment method object." 
    // },
    // { code: "payment_method_microdeposit_failed", message: "" },
    // { code: "payment_method_microdeposit_verification_amounts_invalid", message: "" },
    // { code: "payment_method_microdeposit_verification_amounts_mismatch", message: "" },
    // { code: "payment_method_microdeposit_verification_attempts_exceeded", message: "" },
    // { code: "payment_method_microdeposit_verification_timeout", message: "" },
    { 
        code: "payment_method_provider_decline", 
        message: "The payment was declined by the issuer or customer." 
    },
    { 
        code: "payment_method_provider_timeout", 
        message: "The payment method failed due to a timeout." 
    },
    { 
        code: "payment_method_unactivated", 
        message: "The operation cannot be performed as the payment method used has not been activated."
    },
    // { 
    //     code: "payment_method_unexpected_state", 
    //     message: "The provided payment method’s state was incompatible with the operation you were trying to perform. Confirm that the payment method is in an allowed state for the given operation before attempting to perform it."
    // },
    // { 
    //     code: "payment_method_unsupported_type", 
    //     message: "The API only supports payment methods of certain types." 
    // },
    // { code: "payouts_not_allowed", message: "" },
    // { code: "platform_account_required", message: "" },
    // { code: "platform_api_key_expired", message: "" },
    { 
        code: "postal_code_invalid", 
        message: "The postal code provided was incorrect." 
    },
    { 
        code: "processing_error", 
        message: "An error occurred while processing the card. Try again later or with a different payment method." 
    },
    // { code: "product_inactive", message: "" },
    // { code: "rate_limit", message: "" },
    // { code: "resource_already_exists", message: "" },
    // { code: "resource_missing", message: "" },
    // { code: "routing_number_invalid", message: "" },
    // { code: "secret_key_required", message: "" },
    // { code: "sepa_unsupported_account", message: "" },
    // { code: "setup_attempt_failed", message: "" },
    // { code: "setup_intent_authentication_failure", message: "" },
    // { code: "setup_intent_invalid_parameter", message: "" },
    // { code: "setup_intent_unexpected_state", message: "" },
    // { code: "shipping_calculation_failed", message: "" },
    // { code: "sku_inactive", message: "" },
    // { code: "state_unsupported", message: "" },
    // { code: "tax_id_invalid", message: "" },
    // { code: "taxes_calculation_failed", message: "" },
    // { code: "terminal_location_country_unsupported", message: "" },
    // { code: "testmode_charges_only", message: "" },
    // { code: "tls_version_unsupported", message: "" },
    // { code: "token_already_used", message: "" },
    // { code: "token_in_use", message: "" },
    // { code: "transfers_not_allowed", message: "" },
    // { code: "upstream_order_creation_failed", message: "" },
    // { code: "url_invalid", message: "" }
];