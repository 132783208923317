import httpClient from "../utilities/HttpClient"

var routes = {
  createPaymentIntent: "/api/guesty/createPaymentIntent",
}

class PaymentService {
  static async CreatePaymentIntent(data, token) {
    return httpClient.Post(routes.createPaymentIntent, data, token)
  }
}

export default PaymentService
